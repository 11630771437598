import List from '@mui/material/List';
import React from 'react';
import { ContextPaper } from './context-paper';
import { ListHeader } from './list-header';
export function ContextMenu(props) {
    return (React.createElement(ContextPaper, { elevation: 3, onMouseLeave: () => {
            props.onClose();
        } },
        React.createElement(ListHeader, null, props.title),
        React.createElement(List, null, props.listItems)));
}
