import { FontAwesomeIcon } from '@mae/icons/base-icons';
import { isIconDefinition } from '@mae/icons/utils';
import { MAEDefaultI18NextProvider } from '@mae/localization';
import { useLocalStorage } from '@mae/utils/hooks/use-local-storage';
import { Tooltip } from '@mui/material';
import List from '@mui/material/List';
import Popper from '@mui/material/Popper';
import React from 'react';
import { FlexScrollBarWrapper, ScrollBar } from '../scroll-bar';
import { MAEDefaultThemeProvider } from '../themes';
import { Collapse } from './collapse';
import { CollapseToggles } from './collapse-toggles';
import { ContextMenu } from './context-menu';
import { Drawer } from './drawer';
import { ExpandIcon } from './expand-icon';
import { ListItem } from './list-item';
import { ListItemIcon } from './list-item-icon';
import { ListItemText } from './list-item-text';
import { ListSubItems } from './list-sub-items';
import { Root } from './root';
function getActive(route, menuItems) {
    if (!route) {
        return {
            parentKey: '',
            subItemKey: menuItems[0].subItems.length ? menuItems[0].subItems[0].key : menuItems[0].key
        };
    }
    // slicing to 2 items since we only support 2 deep menu items
    const parts = route.split('/').slice(0, 2);
    const result = {};
    if (parts.length === 2) {
        result.parentKey = parts[0];
        result.subItemKey = parts.join('/');
    }
    if (parts.length === 1) {
        // get top level
        const item = menuItems.find((item) => item.key === parts[0]);
        if (item.subItems.length) {
            // default view
            result.parentKey = item.key;
            result.subItemKey = item.subItems[0].key;
        }
        else {
            result.parentKey = route;
            result.subItemKey = route;
        }
    }
    return result;
}
const storageKey = 'mae-component-menu';
export const Menu = (props) => {
    const selected = getActive(props.defaultSelection, props.menuItems);
    const isRtl = document.body.classList.contains('rtl');
    const [storage, storageUpdate] = useLocalStorage(storageKey, {
        open: true,
        pinned: props.collapsable === false || true
    });
    // state
    const [contextMenuData, setContextMenuData] = React.useState(null);
    const [nestedOpen, setNestedOpen] = React.useState(selected.parentKey);
    const [selectedView, setSelectedView] = React.useState(selected.subItemKey);
    const setActive = (route) => {
        const full = route.slice(0, 2).join('/');
        const active = getActive(full, props.menuItems);
        if (!active) {
            return;
        }
        setNestedOpen(active.parentKey);
        setSelectedView(active.subItemKey);
    };
    const toggleDrawer = () => {
        handleContextMenuClose();
        storageUpdate('open', !storage.open);
    };
    const handlePin = () => {
        storageUpdate('pinned', !storage.pinned);
    };
    const handleCustomTopLevel = (item) => {
        if (item.disabled) {
            return;
        }
        if (item.subItems.length === 0) {
            if (item.selectable) {
                if (!storage.pinned) {
                    storageUpdate('open', false);
                }
                setSelectedView(item.key);
            }
            if (!item.disabled) {
                item.action();
            }
        }
        else {
            const newItem = item.key === nestedOpen ? 0 : item.key;
            handleContextMenuClose();
            setNestedOpen(newItem);
        }
    };
    const handleCustomSubItemSelection = (subItem) => {
        if (subItem.selectable) {
            if (!storage.pinned) {
                storageUpdate('open', false);
            }
            setSelectedView(subItem.key);
        }
        if (!subItem.disabled) {
            handleContextMenuClose();
            subItem.action();
        }
    };
    const handleContextMenuClose = () => {
        setContextMenuData(null);
    };
    // Handle updates from backbone view change events
    props.menuItems.forEach((menuItem) => (menuItem.onActivate = (route) => {
        if (!route) {
            return;
        }
        setActive(route);
    }));
    const topCustomLevel = props.menuItems.map((item) => {
        const shouldShowContext = nestedOpen !== item.key && !item.disabled && item.subItems.length !== 0;
        const shouldHideTooltip = storage.open || (shouldShowContext && item.subItems.length !== 0);
        return (React.createElement(React.Fragment, { key: item.key },
            React.createElement(ListItem, { disabled: item.disabled, onMouseEnter: (event) => {
                    // don't show the menu if the main menu is already expanded
                    if (shouldShowContext) {
                        setContextMenuData({
                            anchor: event.currentTarget,
                            title: item.label,
                            listItems: (React.createElement(ListSubItems, { groupSelected: false, menuItems: item.subItems, selectedView: selectedView, onClick: (menuItem) => handleCustomSubItemSelection(menuItem), showTooltip: !storage.open }))
                        });
                    }
                    else {
                        handleContextMenuClose();
                    }
                }, onClick: () => handleCustomTopLevel(item), selected: nestedOpen === item.key },
                React.createElement(Tooltip, { title: shouldHideTooltip ? '' : item.label, placement: "right" },
                    React.createElement(ListItemIcon, { selected: selectedView === item.key && item.subItems.length === 0 },
                        item.icon && !item.maeIcon && (React.createElement("div", { className: `${item.icon}` }, item.icon ? undefined : item.label.substring(0, 1).toLocaleUpperCase())),
                        item.maeIcon &&
                            (isIconDefinition(item.maeIcon) ? (React.createElement(FontAwesomeIcon, { icon: item.maeIcon })) : (React.createElement(FontAwesomeIcon, { ...item.maeIcon }))))),
                React.createElement(ListItemText, { selected: selectedView === item.key && item.subItems.length === 0, topLevelSelected: nestedOpen === item.key, primary: item.label }),
                item.subItems.length !== 0 && React.createElement(ExpandIcon, { expanded: nestedOpen === item.key })),
            item.subItems.length !== 0 && (React.createElement(Collapse, { in: nestedOpen === item.key || item.alwaysVisible, timeout: "auto", unmountOnExit: true },
                React.createElement(List, { component: "div", disablePadding: true },
                    React.createElement(ListSubItems, { groupSelected: true, menuItems: item.subItems, selectedView: selectedView, onClick: (menuItem) => handleCustomSubItemSelection(menuItem), onMouseEnter: handleContextMenuClose, showTooltip: !storage.open }))))));
    });
    const topPosition = props.collapseButtonOrientation === 'top';
    const collapsibleComponent = props.collapsable === undefined || props.collapsable ? (React.createElement(CollapseToggles, { isRtl: isRtl, isTopPosition: topPosition, isDrawerOpen: storage.open, isPinned: storage.pinned, onPin: handlePin, onToggleDrawer: toggleDrawer })) : null;
    return (React.createElement(MAEDefaultI18NextProvider, null,
        React.createElement(MAEDefaultThemeProvider, null,
            React.createElement(Root, null,
                React.createElement(Drawer, { onMouseLeave: handleContextMenuClose, open: storage.open, variant: "permanent" },
                    contextMenuData && (React.createElement(Popper, { style: { zIndex: 9999 }, id: storageKey.concat('-popper-', contextMenuData.title), open: contextMenuData.anchor !== null, placement: "right-start", anchorEl: contextMenuData.anchor },
                        React.createElement(ContextMenu, { onClose: handleContextMenuClose, title: contextMenuData.title, listItems: contextMenuData.listItems }))),
                    topPosition && collapsibleComponent,
                    React.createElement(FlexScrollBarWrapper, null,
                        React.createElement(ScrollBar, { direction: isRtl ? 'rtl' : 'ltr' },
                            React.createElement(List, { component: "div", disablePadding: true, sx: {
                                    overflowX: 'clip'
                                } }, topCustomLevel))),
                    !topPosition && collapsibleComponent)))));
};
